import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../css/Home.css';
import { MobileView, BrowserView } from 'react-device-detect';

const Home = () => {
    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, []);
    return (
        <div className="w-100">
            <BrowserView>
                <img src={require('../images/background.png')} alt="background" style={{ position: 'absolute', marginTop: 150 }} />
                <Navbar />
                <div className="container">
                    <div className="row d-flex align-items-center" style={{ paddingBottom: '8%', paddingTop: '8%' }}>
                        <div data-aos="fade-right" className="col-md-6">
                            <h1 style={{ fontSize: 60 }}>Software Design and Development</h1>

                            <hr style={{ height: 2 }} />

                            <p className="lead">
                                Getting a high quality and well designed applications
                            </p>
                        </div>

                        <div data-aos="fade-left" className="col-md-6">
                            <img
                                src={require('../images/home.png')}
                                alt="home_sec"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <Navbar />
                <div className="container">
                    <div className="row d-flex align-items-center" style={{ paddingTop: '5%' }}>
                        <div data-aos="fade-left" className="col-md-6">
                            <img
                                src={require('../images/home8.jpg')}
                                alt="home_sec"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </div>
                        <div data-aos="fade-right" className="col-md-6">
                            <h1 style={{ fontSize: 40 }}>Software Design and Development</h1>

                            <hr style={{ height: 2 }} />

                            <p className="lead">
                                Getting a high quality and well designed applications
                            </p>
                        </div>
                    </div>
                </div>
            </MobileView>
        </div>
    );
};

export default Home;