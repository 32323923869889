import React from 'react';
import '../css/Navbar.css';
import { BrowserView, MobileView } from 'react-device-detect'

const Navbar = () => (
  <section id="home">
    <BrowserView>
      <nav
        className="navbar navbar-expand-lg navbar-light" //rgba(0,0,0,0.8)
      >
        <div className="container">
          <a className="navbar-brand" href="#home">
            <img
              src={require('../images/logo_osaid.png')}
              alt="logo"
              className="logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto ">
              <li className="nav-item pr-3">
                <a className="nav-link font-weight-bold" href="#home">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item pr-3">
                <a className="nav-link font-weight-bold" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item pr-3">
                <a className="nav-link font-weight-bold" href="#services">
                  Our Services
                </a>
              </li>
              <li className="nav-item pr-3">
                <a className="nav-link font-weight-bold" href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li className="nav-item pr-3">
                <a className="nav-link font-weight-bold" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </BrowserView>

    <MobileView>
      <nav
        className="navbar navbar-expand-lg navbar-light" //rgba(0,0,0,0.8)
      >
        <div className="container">
          <a className="navbar-brand" href="#home">
            <img
              src={require('../images/logo_osaid.png')}
              alt="logo"
              className="logo"
              style={{ backgroundSize: 'cover', height: 80, width: 170 }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item-mobile">
                <a className="nav-mobile font-weight-bold" href="#home">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item-mobile">
                <a className="nav-mobile font-weight-bold" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item-mobile">
                <a className="nav-mobile font-weight-bold" href="#services">
                  Our Services
                </a>
              </li>
              <li className="nav-item-mobile">
                <a className="nav-mobile font-weight-bold" href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li className="nav-item-mobile">
                <a className="nav-mobile font-weight-bold" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </MobileView>
  </section>
);

export default Navbar;
