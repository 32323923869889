import React, { Component } from 'react';
import 'react-bnb-gallery/dist/style.css';
import '../css/AppViewer.css';

import ReactBnbGallery from 'react-bnb-gallery'

class AppViewer extends Component {
  constructor() {
    super(...arguments);
    this.state = { galleryOpened: false, photos: [] };
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  componentDidMount() {
    this.updatePhotos()
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props

    if (project.name === prevProps.project.name) {
      return
    }
    this.updatePhotos()
  }

  updatePhotos() {
    const { project, projectType } = this.props
    const photosArr = project.images.map(img => {
      return {
        photo: img,
        caption: project.name,
        subcaption:
          projectType === 'Mobile'
            ? <div key={project.name}>
              <a target="_blank" href={project.googlePlay} rel="noopener noreferrer" style={{ color: 'white' }}>Google Play</a>
              <a target="_blank" href={project.appStore} rel="noopener noreferrer" style={{ color: 'white', marginLeft: 10 }}>App Store</a>
              {project.appGallery &&
                <a target="_blank" href={project.appGallery} rel="noopener noreferrer" style={{ color: 'white', marginLeft: 10 }}>App Gallery</a>
              }
            </div>
            : <div>
              <a href={project.link} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Go to Website</a>
            </div>,
        thumbnail: img,
      }
    })

    this.setState({ photos: photosArr })
  }

  toggleGallery() {
    this.setState(prevState => ({
      galleryOpened: !prevState.galleryOpened
    }));
  }

  render() {
    const { project } = this.props
    return (
      <div className="col-md-4">
        <div className="containers">
          <img
            className="image"
            data-aos="fade-left"
            src={project.mainImage}
            alt={project.name}
            onClick={this.toggleGallery}
          />
          <div className="middle">
            <img
              className="logo-portfolio"
              src={project.logo}
              alt={project.name}
              onClick={this.toggleGallery}
            />
          </div>
          <ReactBnbGallery
            show={this.state.galleryOpened}
            photos={this.state.photos}
            onClose={this.toggleGallery} />
        </div>
      </div>
    )
  }
}

export default AppViewer;
