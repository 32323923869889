import React, { useEffect } from 'react';
import '../css/Contact.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { MobileView, BrowserView } from 'react-device-detect';

const Contact = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);
  return (
    <div>
      <BrowserView>
        <section
          id="contact"
          style={{ justifyContent: 'center', display: 'flex', paddingTop: '5%' }}
        >

          <img src={require('../images/contact.png')} alt="contact" style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          <div style={{ zIndex: 2 }} data-aos="zoom-in">
            <h2 style={{ marginTop: '10%' }} className="text-center">Contact Us</h2>
            <p className="text-center" style={{ fontSize: 24 }}>
              Osaid Aljada & His Partner Company
            </p>
            <p className="text-center" style={{ fontSize: 20 }}>
              E-mail: info@mate-co.com
            </p>
            <p className="text-center" style={{ fontSize: 20 }}>
              <a
                href="https://goo.gl/maps/8Xy1TqRH2552thX96"
                className="text-center"
                style={{ textDecorationLine: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              ><i style={{ fontSize: 20, color: '#EA4335' }} className="fas fa-map-marker-alt" /> Al-Rabiah-Abdullah Ben Rawahah St.
              </a>
            </p>
            <p className="text-center" style={{ fontSize: 20 }}>
              Mob: +962 7 7017 6608
              <br />
              Mob: +962 7 9726 9775
            </p>

            <p className="text-center d-flex justify-content-center" style={{ fontSize: 20 }}>
              Follow Us on <a
                href="https://web.facebook.com/yourmate.co/"
                className="fab fa-facebook ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >{' '}</a> <a
                href="https://www.instagram.com/mate_co1/"
                className="fab fa-instagram ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >{' '}</a>
            </p>
            <p style={{ fontSize: 15 }} className='text-center d-flex justify-content-center'>Copyright © 2024 All Rights Reserved</p>
          </div>
        </section>
      </BrowserView>

      <MobileView>
        <section
          id="contact"
          style={{ justifyContent: 'center', display: 'flex', paddingTop: '5%' }}
        >
          <div style={{ zIndex: 2, marginTop: '2%' }} data-aos="zoom-in">
            <h2 className="text-center">Contact Us</h2>
            <p className="text-center" style={{ fontSize: 24 }}>
              Osaid Aljada & His Partner Company
            </p>
            <p className="text-center" style={{ fontSize: 24 }}>
              E-mail: info@mate-co.com
            </p>
            <p className="text-center" style={{ fontSize: 24 }}>
              <a
                href="https://goo.gl/maps/8Xy1TqRH2552thX96"
                className="text-center"
                style={{ textDecorationLine: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              ><i style={{ fontSize: 24, color: '#EA4335' }} className="fas fa-map-marker-alt" /> Al-Rabiah-Abdullah Ben Rawahah St.
              </a>
            </p>
            <p className="text-center" style={{ fontSize: 24 }}>
              Mob: +962 7 7017 6608
              <br />
              Mob: +962 7 9726 9775
            </p>

            <p className="text-center d-flex justify-content-center" style={{ fontSize: 24 }}>
              Follow Us on <a
                href="https://web.facebook.com/yourmate.co/"
                className="fab fa-facebook ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >{' '}</a> <a
                href="https://www.instagram.com/mate_co1/"
                className="fab fa-instagram ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >{' '}</a>
            </p>
            <p style={{ fontSize: 15 }} className='text-center d-flex justify-content-center'>Copyright © 2024 All Rights Reserved</p>
          </div>
        </section>
      </MobileView>
    </div>
  )
};

export default Contact;
