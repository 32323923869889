import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { isMobileOnly } from 'react-device-detect';

const About = () => {
  
  useEffect(() => {
    Aos.init({
      duration : 2000
    });
  }, []);

  return (
  <section id="about" style={{ paddingTop: '10%', paddingBottom: '1%' }}>
    <div className="container">
      <div className="row">
        <div data-aos="fade-down" className="col-md-6">
          {isMobileOnly ?
            <img
            src={require('../images/about-us.png')}
            className="img-fluid"
            alt="about"
            style={{ marginBottom: '3%', marginTop: '25%' }}
          />
          : 
            <img
            src={require('../images/about-us.png')}
            className="img-fluid"
            alt="about"
            style={{ marginBottom: '3%', width: '100%', height: '80%', objectFit: 'contain' }}
            />}
        </div>
        <div className="col">
          <h2 data-aos="fade-left" style={{ color: '#C36294', fontWeight: 'bold' }}>Who Are We?</h2>
          <hr />
          <p data-aos="slide-up" className="lead" style={{ marginTop: '10%' }}>
            <span style={{ fontWeight: 'bold' }}>Mate</span> Company was
            established as a young company ran by qualified engineers equipped
            by motivation and ambition.
            <br /> <br />
            <span style={{ fontWeight: 'bold' }}>Mate</span> was found to handle
            software solutions including image proccessing, web and mobile design.
            <br /> <br />
            "Be Our Mate" is a slogan we hold up high, which means that our
            clients are our business mates and our success comes form their
            success.
          </p>
        </div>
      </div>
    </div>
  </section>
)};

export default About;
