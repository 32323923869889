export const mobileProjects = [
  {
    'name': "AlQadi Sweets - حلويات القاضي",
    'logo': require('../images/alqadi_logo.png'),
    'appStore': "https://apps.apple.com/jo/app/%D8%AD%D9%84%D9%88%D9%8A%D8%A7%D8%AA-%D8%A7%D9%84%D9%82%D8%A7%D8%B6%D9%8A-alqadi-sweets/id1547077008",
    'googlePlay': "https://play.google.com/store/apps/details?id=com.mate.AlQadi",
    'appGallery': "https://appgallery.huawei.com/#/app/C103737691?locale=en_GB&source=appshare&subsource=C103737691",
    'mainImage': require('../images/alqadi_app_phone.png'),
    'images': [
      require('../images/alqadi-1.png'),
      require('../images/alqadi-2.png'),
      require('../images/alqadi-3.png'),
      require('../images/alqadi-4.png')
    ]
  },
  {
    'name': 'Baghdad Corner - ركن بغداد',
    'logo': require('../images/baghdad-logo.png'),
    'appStore': "https://apps.apple.com/jo/app/%D8%B1%D9%83%D9%86-%D8%A8%D8%BA%D8%AF%D8%A7%D8%AF-baghdad-corner/id1535010384",
    'googlePlay': "https://play.google.com/store/apps/details?id=com.amrjada.Baghdad",
    'appGallery': "https://appgallery.cloud.huawei.com/ag/n/app/C103003261?locale=en_GB&source=appshare&subsource=C103003261",
    'mainImage': require('../images/baghdad.png'),
    'images': [
      require('../images/baghdad-1.png'),
      require('../images/baghdad-2.png'),
      require('../images/baghdad-3.png'),
      require('../images/baghdad-4.png'),
      require('../images/baghdad-5.png')
    ]
  },
  {
    'name': 'Smart Lab',
    'logo': require('../images/smart_logo.png'),
    'appStore': "https://apps.apple.com/jo/app/%D8%B3%D9%85%D8%A7%D8%B1%D8%AA-%D9%84%D8%A7%D8%A8/id1672958690?platform=iphone",
    'googlePlay': "https://play.google.com/store/apps/details?id=com.mate.smartlab",
    'mainImage': require('../images/smart_main.png'),
    'images': [
      require('../images/smart-1.png'),
      require('../images/smart-2.png'),
      require('../images/smart-3.png')
    ]
  },
  {
    'name': 'Weeze',
    'logo': require('../images/weeze_logo.png'),
    'appStore': "https://apps.apple.com/app/id1573006530",
    'googlePlay': "https://play.google.com/store/apps/details?id=com.mateco.Weezy",
    'mainImage': require('../images/weeze_main.jpg'),
    'images': [
      require('../images/weeze-1.png'),
      require('../images/weeze-2.png'),
      require('../images/weeze-3.png'),
      require('../images/weeze-4.png')
    ]
  },
  {
    'name': 'Azka',
    'logo': require('../images/azka_logo.png'),
    'appStore': "https://apps.apple.com/jo/app/azka/id1602493289",
    'googlePlay': "https://play.google.com/store/apps/details?id=com.mateco.Azka",
    'mainImage': require('../images/azka_main.jpg'),
    'images': [
      require('../images/azka-1.png'),
      require('../images/azka-2.png'),
      require('../images/azka-3.png'),
      require('../images/azka-4.png')
    ]
  }
]

export const webProjects = [
  {
    'name': "Baghdad Corner-ركن بغداد",
    'logo': require('../images/baghdad-logo.png'),
    'link': 'https://www.rokonbaghdad.com',
    'mainImage': require('../images/baghdad-web-1.png'),
    'images': [
      require('../images/baghdad-web-1.png'),
      require('../images/baghdad-web-2.png'),
      require('../images/baghdad-web-3.png'),
      require('../images/baghdad-web-4.png'),
    ]
  },
  {
    'name': "Azka Sweets",
    'logo': require('../images/azka_logo.png'),
    'link': 'https://www.azkahd.com',
    'mainImage': require('../images/azka_web_1.png'),
    'images': [
      require('../images/azka_web_1.png'),
      require('../images/azka_web_2.png'),
      require('../images/azka_web_3.png'),
      require('../images/azka_web_4.png'),
      require('../images/azka_web_5.png')
    ]
  },
  {
    'name': "Phoenix - Cargo Services",
    'logo': require('../images/phoenex_logo.png'),
    'link': "http://www.phoenix-shippingservice.com",
    'mainImage': require('../images/phoenix-web-1.png'),
    'images': [
      require('../images/phoenix-web-1.png'),
      require('../images/phoenix-web-2.png'),
    ]
  },
  {
    'name': "Galactic Logistic Services",
    'logo': require('../images/galactic-logo.png'),
    'link': "http://www.galacticlog.com",
    'mainImage': require('../images/galactic.png'),
    'images': [
      require('../images/galactic.png'),
      require('../images/galactic-2.png'),
    ]
  }
]