import React, { useState, useEffect } from 'react';
import '../css/Portfolio.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import AppViewer from '../components/AppViewer';
import { mobileProjects, webProjects } from '../Data/projects';
import { isMobileOnly } from 'react-device-detect';

const Portfolio = () => {
    const [projectType, setProjectType] = useState('Mobile')

    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, []);

    return (
        <section id="portfolio" style={{ paddingBottom: '5%' }}>
            <div className="container">
                <div className="text-center">
                    {isMobileOnly ?
                        <h1 className="portfolio-title" data-aos="fade-left" style={{ marginBottom: '5%', marginTop: '55%', fontWeight: 'bold' }}>
                            Portfolio
                </h1>
                        :
                        <h1 className="portfolio-title" data-aos="fade-up" style={{ marginBottom: '5%', fontWeight: 'bold' }}>
                            Portfolio
                </h1>}
                </div>
                <div className="row container" data-aos="fade-left">

                    {isMobileOnly
                        ? <div
                            className={projectType === 'Mobile' ? "portfolio-section-mobile text-danger" : 'portfolio-section-mobile'}
                            onClick={() => setProjectType('Mobile')}
                        >
                            Mobile Applications
                        </div>
                        :
                        <div
                            className={projectType === 'Mobile' ? "portfolio-section text-danger" : 'portfolio-section'}
                            onClick={() => setProjectType('Mobile')}
                        >
                            Mobile Applications
                        </div>
                    }

                    {isMobileOnly ? <div className="dash-mobile">|</div> : <div className="dash">|</div>}

                    {isMobileOnly
                        ? <div
                            className={projectType === 'Web' ? "portfolio-section-mobile text-danger" : 'portfolio-section-mobile'}
                            onClick={() => setProjectType('Web')}
                        >
                            Web Applications
                        </div>
                        : <div
                            className={projectType === 'Web' ? "portfolio-section text-danger" : 'portfolio-section'}
                            onClick={() => setProjectType('Web')}
                        >
                            Web Applications
                        </div>
                    }
                </div>
                <hr />
                {projectType === 'Mobile'
                    ? <div className="row">
                        {mobileProjects.map(project => <AppViewer key={project.name} project={project} projectType={projectType} />)}
                    </div>
                    : <div className="row">
                        {webProjects.map(project => <AppViewer key={project.name} project={project} projectType={projectType} />)}
                    </div>
                }
            </div>
        </section>
    )
};

export default Portfolio;