import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
//import '../css/Services.css';
import { isMobileOnly } from 'react-device-detect';

const Services = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);

  return (
    <section id="services" style={{ paddingTop: '5%' }}>
      <div className="container">
        <div className="row d-flex align-items-center" style={{ paddingBottom: '8%' }}>
          <div data-aos="fade-right" className="col-md-4">
            <img
              src={require('../images/mobile_web.jpg')}
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              alt="Home" />
            {isMobileOnly
              ? <h4 className="home-title-mobile">Web and Mobile Development</h4>
              : <h1 className="home-title">Web and Mobile Development</h1>
            }

            <p className="lead text-center">
              Develop your robust and high quality mobile and web applications
            </p>

            <div className="d-flex justify-content-center">
              <a href="#mobile-and-web">
                <button
                  type="button"
                  className="btn btn-warning text-white text-center">
                  Learn more
                </button>
              </a>
            </div>
          </div>
          <div data-aos="fade" className="col-md-4">
            <img
              src={require('../images/computer-vision-ai.png')}
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              alt="Home" />
            {isMobileOnly
              ? <h4 className="home-title-mobile">Computer Vision</h4>
              : <h1 className="home-title">Computer Vision</h1>
            }

            <p className="lead text-center">
              Develop your Computer vision applications using state-of-the-art Artificial Intelligence and image processing algorithms
            </p>

            <div className="d-flex justify-content-center">
              <a href="#ai">
                <button type="button" className="btn btn-primary text-center">Learn more</button>
              </a>
            </div>
          </div>
          <div data-aos="fade-left" className="col-md-4">
            <img
              src={require('../images/ui-ux.png')}
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              alt="Home" />
            {isMobileOnly
              ? <h4 className="home-title-mobile">UI/UX</h4>
              : <h1 className="home-title">UI/UX</h1>
            }

            <p className="lead text-center">
              Design your useful, easy to use, and delightful to interact with applications
            </p>

            <div className="d-flex justify-content-center">
              <a href="#ui-ux">
                <button type="button" className="btn ui-button text-center">Learn more</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Services;
