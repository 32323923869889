import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import Home from './screens/Home';
import About from './screens/About';
import Services from './screens/Services';
import AI from './screens/AI';
import UiUx from './screens/UiUx';
import Portfolio from './screens/Portfolio';
import Contact from './screens/Contact';
import history from './history';
import MobileAndWeb from './screens/MobileAndWeb';

const App = () => (
  <HttpsRedirect>
    <Router history={history}>
      <Route path="/" exact component={Home} />
      <Route path="/" exact component={About} />
      <Route path="/" exact component={Services} />
      <Route path="/" exact component={MobileAndWeb} />
      <Route path="/" exact component={AI} />
      <Route path="/" exact component={UiUx} />
      <Route path="/" exact component={Portfolio} />
      <Route path="/" exact component={Contact} />
    </Router>
  </HttpsRedirect>
);

export default App;
