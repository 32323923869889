import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { MobileView, BrowserView } from 'react-device-detect';

const UiUx = () => {
    useEffect(() => {
        Aos.init({
          duration : 2000
        });
      }, []);
    return(
        <section id="ui-ux" style={{ paddingTop: '10%', paddingBottom: '10%' }}>
            <BrowserView>
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-left" className="col-md-6">
                            <h2 style={{ color: '#C36294', fontWeight: 'bold' }}>User Interface / User Experience</h2>
                            <hr />
                            <p data-aos="slide-up" className="lead" style={{ marginTop: '5%' }}>
                                We make the user interaction with applications as simple and efficient as possible, in terms of accomplishing user goals.
                            </p>
                        </div>
                        
                        <div data-aos="fade-right" className="col-md-6">
                            <img src={require('../images/ui-ux-section.png')} alt="ui-ux" style={{width: '100%', height: '90%', objectFit: 'contain' }}  />
                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
            <div className="container">
                    <div className="row">
                        <div data-aos="fade-right" className="col-md-6">
                            <img src={require('../images/ui-ux-section.png')} alt="ui-ux" style={{width: '100%', height: '90%', objectFit: 'contain' }}  />
                        </div>
                        <div data-aos="fade-left" className="col-md-6">
                            <h2 style={{ color: '#C36294', fontWeight: 'bold' }}>User Interface / User Experience</h2>
                            <hr />
                            <p data-aos="slide-up" className="lead" style={{ marginTop: '10%' }}>
                            We make the user interaction with applications as simple and efficient as possible, in terms of accomplishing user goals.
                            </p>
                        </div>
                    </div>
                </div>
            </MobileView>
        </section>
    );
};

export default UiUx;