import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { isMobileOnly } from 'react-device-detect';

const AI = () => {
    useEffect(() => {
        Aos.init({
          duration : 2000
        });
      }, []);
    return(
      <section  id="ai" style={{ paddingTop: '10%', paddingBottom: '10%' }}>
        {isMobileOnly
          ? <div></div>
          : <svg style={{position: 'absolute'}} height="90%" width="100%" id="bg-svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,600 C 0,600 0,200 0,200 C 127.60765550239236,219.23444976076553 255.21531100478472,238.46889952153109 337,220 C 418.7846889952153,201.53110047846891 454.7464114832535,145.35885167464116 549,153 C 643.2535885167465,160.64114832535884 795.7990430622012,232.09569377990428 906,241 C 1016.2009569377988,249.90430622009572 1084.0574162679425,196.25837320574163 1166,179 C 1247.9425837320575,161.74162679425837 1343.9712918660289,180.87081339712918 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="#e0cdbb88" className="transition-all duration-300 ease-in-out delay-150"></path><path d="M 0,600 C 0,600 0,400 0,400 C 113.94258373205744,394.3349282296651 227.8851674641149,388.66985645933016 332,404 C 436.1148325358851,419.33014354066984 530.401913875598,455.6555023923445 620,458 C 709.598086124402,460.3444976076555 794.5071770334929,428.7081339712919 882,424 C 969.4928229665071,419.2918660287081 1059.5693779904307,441.511961722488 1153,442 C 1246.4306220095693,442.488038277512 1343.2153110047848,421.244019138756 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="#e0cdbbff" className="transition-all duration-300 ease-in-out delay-150"></path></svg>
        }
        
        <div className="container">
      <div className="row">
        <div data-aos="fade-right" className="col-md-6">
          <img src={require('../images/ai-section6.png')} alt="ai" style={{width: '100%', height: '100%', objectFit: 'contain' }}  />
        </div>
        <div data-aos="fade-left" className="col-md-6">
          <h2 style={{ color: '#C36294', fontWeight: 'bold' }}>Computer Vision</h2>
          <hr />
          <p className="lead" style={{ marginTop: '5%' }}>
          We provide machine vision solutions for both real time and offline applications in industry and robotics utilizing state-of-the-art machine learning, deep learning and image processing algorithms.
          </p>
        </div>
      </div>
    </div>
        </section>
    );
};

export default AI;