import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { MobileView, BrowserView } from 'react-device-detect';

const MobileAndWeb = () => {
    useEffect(() => {
        Aos.init({
          duration : 2000
        });
      }, []);
    return(
        <section id="mobile-and-web" style={{paddingTop: '12%'}}>
            <BrowserView>
            <div className="container">
                <div className="row">
                    <div data-aos="fade-right" className="col-md-6">
                        <h2 style={{ color: '#C36294', fontWeight: 'bold' }}>Mobile and Web Development</h2>
                        <hr />
                        <h3>Web Development</h3>
                            <p className="lead">
                                Full stack web development following best practices 
                                <br />
                                providing high performance experience
                            </p>
                        <h3 style={{ paddingTop: '2%' }}>Mobile Apps Development</h3>
                            <p className="lead">
                                Develop best performant and great designed applications on both android
                                and ios
                            </p>
                        <h3 style={{ paddingTop: '2%' }}>E-Commerce Applications</h3>
                            <p className="lead">
                                Build your digital store with online payment and order tracking
                            </p>
                    </div>
                    <div data-aos="fade-left" className="col-md-6">
                        <img src={require('../images/mobile_and_web.png')} alt="mobile and web" style={{width: '100%', height: '90%', objectFit: 'contain' }}  />
                    </div>
                </div>
            </div>
            </BrowserView>

            <MobileView>
            <div className="container">
                <div className="row">
                    <div data-aos="fade-left" className="col-md-6">
                        <img src={require('../images/mobile_and_web.png')} alt="mobile and web" style={{width: '100%', height: '90%', objectFit: 'contain' }}  />
                    </div>
                    <div data-aos="fade-right" className="col-md-6">
                        <h2 style={{ color: '#C36294', fontWeight: 'bold' }}>Mobile and Web Development</h2>
                        <hr />
                        <h3>Web Development</h3>
                            <p className="lead">
                                Full stack web development following best practices 
                                <br />
                                providing high performance experience
                            </p>
                        <h3 style={{ paddingTop: '2%' }}>Mobile Apps Development</h3>
                            <p className="lead">
                                Develop best performant and great designed applications on both android
                                and ios
                            </p>
                        <h3 style={{ paddingTop: '2%' }}>E-Commerce Applications</h3>
                        <p className="lead">
                            Build your digital store with online payment and order tracking
                        </p>
                    </div>
                </div>
            </div>
            </MobileView>
        </section>
    );
};

export default MobileAndWeb